import axios from "@/plugins/axios";
import { mdiLockCheck } from "@mdi/js";

let url = "/api/console/setting";

export default {
    get(){
        return axios.get(url).then(result => result.data);
    },
    put(data){
        return axios.put(url, data).then(result => result.data);
    },
    post(field, image){
        var formData = new FormData(); formData.append("image", image);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${field}`, formData, { headers });
    }
}