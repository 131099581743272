import "@mdi/font/css/materialdesignicons.css"
import Vue from 'vue';
import Vuetify from 'vuetify';

// Translation provided by Vuetify (javascript)
import { ko } from 'vuetify/src/locale';

Vue.use(Vuetify);

export const basicTheme = {
    themes: {
        light: {
            primary: '#00a4e0',
            secondary: '#445163',
            accent: '#00C73C',
            // error: '#FF5252',
            info: '#2D3539',
            content: "#EDF0F5",
            // success: '#4CAF50',
            // warning: '#FFC107',
            anchor: '#333',
        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    }
};

export const consoleTheme = {
    themes: {
        light: {
            primary: '#00C73C',
            secondary: '#445163',
            accent: '#00C73C',
            // error: '#FF5252',
            info: '#2D3539',
            content: "#EDF0F5",
            // success: '#4CAF50',
            // warning: '#FFC107',
            anchor: '#333',
        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    }
};

/**
 * 
 * @param {(basicTheme|consoleTheme)} theme 
 * @returns 
 */
export default function(theme){
    return new Vuetify({
        lang: {
            locales: { ko },
            current: 'ko',
        },
        theme: theme || basicTheme
    });
} 