import axios from "@/plugins/axios";

import banners from "./banners";
import boards from "./boards";
import center from "./center";
import fairs from "./fairs";
import forms from "./forms";
import levels from "./levels";
import messages from "./messages";
import popups from "./popups";
import setting from "./setting";
import shop from "./shop";
import statistics from "./statistics";
import terms from "./terms";
import users from "./users";
export default {
    banners,
    boards,
    center,
    fairs,
    forms,
    levels,
    messages,
    popups,
    setting,
    shop,
    statistics,
    terms,
    users,
    email: {
        post({ to, subject, html }){
            return axios.post("/api/console/email", { to, subject, html }).then(result => result.data);
        }
    },
    message: {
        post({ to, subject, message }){
            return axios.post("/api/console/message", { to, subject, message }).then(result => result.data);
        }
    }
};