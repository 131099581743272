import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from "../plugins/axios";
import { i18n } from "../plugins/vue-i18n";

import Main from '../pages/client/Main.vue';
import { BoardRoutes } from "./board.router";
import { QuestionRoutes } from "./question.router";

Vue.use(VueRouter);

const routes = [
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: '/',
        component: Main
    },
    {
        path: '/common/kcp/cert/request',
        component: () => import('../pages/common/kcp/KCPCertRequest.vue'),
        props: true
    },
    {
        path: '/common/kcp/cert/response',
        component: () => import('../pages/common/kcp/KCPCertResponse.vue'),
        props: true
    },
    {
        // 로그인
        path: "/login",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
        props: true,
    },
    {
        // 비밀번호 찾기
        path: "/login/find-password",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-password.vue"),
        props: true,
    },
    {
        // 아이디 찾기
        path: "/login/find-id",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-id.vue"),
        props: true,
    },
    {
        // 회원가입
        path: "/join",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/main.vue"),
        props: true,
    },
    {
        // 회원가입 폼
        path: "/join/type",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/type.vue"),
        props: true,
    },
    {
        // 회원가입 폼
        path: "/join/form",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/form.vue"),
        props: true,
    },
    {
        // 회원가입 완료
        path: "/join/complete",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/complete.vue"),
        props: true,
    },
    {
        // 회원 정보 변경
        path: "/mypage/myinfo",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
        props: true,
    },
    {
        // 회원 탈퇴
        path: "/mypage/leave",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
        props: true,
    },
    {
        // 매칭상담회
        path: "/mypage/fairs",
        component: () => import(/* webpackChunkName: "fairs" */ "../pages/client/mypage/FairsListPage.vue"),
        props: true,
    },
    {
        // 매칭상담회
        path: "/mypage/fairs/:_fair",
        component: () => import(/* webpackChunkName: "fairs" */ "../pages/client/mypage/FairViewPage.vue"),
        props: true,
    },
    {
        // 상품관리
        path: "/mypage/products",
        component: () => import(/* webpackChunkName: "products" */ "../pages/client/mypage/ProductList.vue"),
        props: true,
    },
    {
        // 상품관리
        path: "/mypage/products/:_product",
        component: () => import(/* webpackChunkName: "products" */ "../pages/client/mypage/ProductInput.vue"),
        props: true,
    },
    {
        // 1:1 문의
        path: "/mypage/question",
        component: () => import(/* webpackChunkName: "question" */ "../pages/client/mypage/question.vue"),
        children: [ ...QuestionRoutes ],
        props: true,
    },
    {
        // 소개
        path: "/about",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/AboutPage.vue"),
        props: true,
    },
    {
        // 소개
        path: "/archive",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/archive/ArchivePage.vue"),
        props: true,
    },
    {
        // 소개
        path: "/archive/:category",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/archive/ArchiveCategoryPage.vue"),
        props: true,
    },
    {
        // 소개
        path: "/archive/:category/:_board",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/archive/ArchiveViewPage.vue"),
        props: true,
    },
    {
        // 소개
        path: "/contact",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/contact/ContactPage.vue"),
        props: true,
    },
    {
        // 소개
        path: "/board",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/board/BoardPage.vue"),
        props: true,
    },
    {
        // 소개
        path: "/board/:_board",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/board/BoardView.vue"),
        props: true,
    },
    {
        // 문의 및 제휴
        path: "/form/inquiry-partner",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/InquiryPartner.vue"),
        props: true,
    },
    {
        // 공지사항
        path: "/center/notice",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },
    {
        // 공지사항
        path: "/center/notice/:_notification",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },
    {
        // 자주묻는질문
        path: "/center/faqs",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/faq.vue"),
        props: true,
    },
    {
        // 개인정보처리방침
        path: "/privacy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/PrivacyPolicyPage.vue"),
        props: true,
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: '/console',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/Main.vue'),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 대시보드
        path: '/console/dashboard',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/Dashboard.vue'),
        props: true
    },
    {
        // 관리자 - 게시판관리
        path: '/console/boards',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/board/BoardList.vue'),
    },
    {
        // 관리자 - 게시판관리
        path: '/console/boards/create',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/board/BoardView.vue'),
    },
    {
        // 관리자 - 게시판관리
        path: '/console/boards/:_board',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/board/BoardView.vue'),
    },
    {
        // 관리자 - 상담회 관리
        path: '/console/fairs',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/fair/FairList.vue'),
    },
    {
        // 관리자 - 상담회 뷰
        path: '/console/fairs/create',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/fair/FairView.vue'),
    },
    {
        // 관리자 - 상담회 뷰
        path: '/console/fairs/:_fair',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/fair/FairView.vue'),
    },
    {
        // 관리자 - 상담회 관리
        path: '/console/fairs/:_fair/matchings',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/fair/MatchingList.vue'),
    },
    {
        // 관리자 - 상담회 관리
        path: '/console/fairs/:_fair/schedules',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/fair/ScheduleView.vue'),
    },
    {
        // 관리자 - 폼메일관리
        path: '/console/forms',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/form/FormList.vue'),
    },
    {
        // 관리자 - 폼메일관리
        path: '/console/forms/:_form',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/form/FormView.vue'),
    },
    {
        // 관리자 - 회원등급
        path: '/console/users/levels',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/user/LevelList.vue'),
        props: true
    },
    {
        // 관리자 - 회원등급
        path: '/console/users/withdrawals',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/user/WithdrawalList.vue'),
        props: true
    },
    {
        // 관리자 - 사용자 정보
        path: '/console/users',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/user/UserList.vue'),
        props: true
    },
    {
        // 관리자 - 사용자 정보
        path: '/console/users/create',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/user/UserView.vue'),
        props: true
    },
    {
        // 관리자 - 사용자 정보
        path: '/console/users/:_user',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/user/UserView.vue'),
        props: true
    },
    {
        // 관리자 - 공지사항
        path: '/console/center/notifications',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/center/Notification.vue'),
        props: true
    },
    {
        // 관리자 - FAQ
        path: '/console/center/faqs',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/center/Faq.vue'),
        props: true
    },
    {
        // 관리자 - 1:1문의
        path: '/console/center/questions',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/center/Question.vue'),
        props: true
    },

    {
        // 관리자 - 상품관리
        path: "/console/shop/products",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductList.vue"),
        props: true,
    },
    {
        // 관리자 - 상품 생성
        path: "/console/shop/products/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductView.vue"),
        props: true,
    },
    {
        // 관리자 - 상품 복사
        path: "/console/shop/products/copy",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductView.vue"),
        props: true,
    },
    {
        // 관리자 - 상품 view
        path: "/console/shop/products/:_product",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductView.vue"),
        props: true,
    },
    {
        // 관리자 - 카테고리 관리
        path: "/console/shop/categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/CategoryList.vue"),
        props: true,
    },

    {
        // 관리자 - 서비스 이용약관
        path: '/console/terms',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/terms/TermsList.vue'),
        props: true
    },
    {
        // 관리자 - 배너관리
        path: '/console/banners',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/banner/BannerList.vue'),
        props: true
    },
    {
        // 관리자 - 배너관리
        path: '/console/banners/create',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/banner/BannerView.vue'),
        props: true
    },
    {
        // 관리자 - 배너관리
        path: '/console/banners/:_banner',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/banner/BannerView.vue'),
        props: true
    },
    {
        // 관리자 - 팝업관리
        path: '/console/popups',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/popup/PopupList.vue'),
        props: true
    },
    {
        // 관리자 - 팝업관리
        path: '/console/setting',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/setting/SettingView.vue'),
        props: true
    },
    {
        // 관리자 - 홈페이지관리 - 인트로
        path: '/console/site/intro',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/site/SiteIntro.vue'),
        props: true
    },
    {
        // 관리자 - 홈페이지관리 - 회사소개
        path: '/console/site/content',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/site/SiteContent.vue'),
        props: true
    },
    {
        // 관리자 - 홈페이지관리 - 연혁
        path: '/console/site/history',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/site/SiteHistory.vue'),
        props: true
    },

    ///////////////////// temp
    {
        // 관리자 - 홈페이지관리 - 연혁
        path: '/console/temps',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/temp/TempList.vue'),
        props: true
    },
    {
        // 관리자 - 홈페이지관리 - 연혁
        path: '/console/temps/:_temp',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/temp/TempView.vue'),
        props: true
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        // 라우터 이동 시 스크롤 상단부터 시작
        return { x: 0, y: 0 }
    },
    routes
})

router.beforeEach((to, from, next) => {

    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("accessToken") ? `Bearer ${sessionStorage.getItem("accessToken")}` : "";
    axios.defaults.headers.common['Accept-Language'] = i18n.locale;

    next();
});

export default router
