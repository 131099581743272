<template>
    <div class="contents">
        <!-- <div class="main-symbol">
            <img src="/images/symbol.svg" alt="DESIGN BIDAM symbol">
        </div> -->

        <!-- 메인 비주얼 -->
        <!-- <main-visual code="main-pc" pc></main-visual>
        <main-visual code="main-mobile" mobile></main-visual> -->

        <!-- 플랫폼 이용 방법 -->
        <!-- <main-section></main-section> -->

        <!-- <v-row justify="center">
            <v-col cols="12" align="center" class="py-0">
                <span class="subtitle-1 font-weight-bold">다국어 샘플</span> <br>
                <span class="caption">(해당 내용은 테스트하고 지우면되요)</span>
            </v-col>
            <v-col cols="auto" class="py-0">
                <locale-select label="언어변경"></locale-select>
            </v-col>
            <v-col cols="12" align="center">
                <div> 한국어 번역 경로: src/locales/ko.js </div>
                <div> 영어 번역 경로: src/locales/en.js </div>
                <div> 중국어 번역 경로: src/locales/cn.js </div>
                <div class="mt-4"> 사용법 $t() 함수 안에, src/locales/{locale}.js 에서 작성한 json 키워드 나열 </div>
                <div class="font-weight-bold mt-4">JSON 작성법1</div>
                <div>
                    {
                        "key1": "value1"
                    }
                </div>
                <div class="font-weight-bold mt-4">JSON 작성법2</div>
                <div>
                    {
                        "key1": {
                            "key2": "value2"
                        }
                    }
                </div>
                <div class="font-weight-bold mt-4">JSON 작성법3 - 우리 회사 룰 대략 아래처럼 정하죠 </div>
                <div>
                    {
                        page: {
                            section1: "내용1",
                            section2: {
                                title: "타이틀",
                                content: "내용"
                            }
                        }
                    }
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example1</div>
                    <div class="body-1">$t("common.로그인")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("common.로그인") }}</div>
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example2</div>
                    <div class="body-1">$t("main.section1")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section1") }}</div>
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example3</div>
                    <div class="body-1">$t("main.section2.title")</div>
                    <div class="body-1">$t("main.section2.content")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section2.title") }}</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section2.content") }}</div>
                </div>
            </v-col>
        </v-row> -->
        <popup-layer></popup-layer>
    </div>
</template>

<script>
// import BoardList from "@/components/client/board/board-list.vue";
// import LocaleSelect from "@/components/client/inc/locale-select.vue";
import PopupLayer from '@/components/client/popup/popup-layer.vue';

export default {
    components: {
        PopupLayer,
        // ClientPage,
        // MainBanners,
        // MainSection,
        // MainVisual,
        // BoardList,
        // LocaleSelect,
    },
    props: {
        isMain: { type: Boolean, default: true },
    },
};
</script>