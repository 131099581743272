import axios from "@/plugins/axios";

let url = "/api/v1/me";

import bookmarks from "./bookmarks";
import carts from "./carts";
import coupons from "./coupons";
import fairs from "./fairs";
import inquires from "./inquires";
import introductions from "./introductions";
import likes from "./likes";
import matchings from "./matchings";
import orders from "./orders";
import points from "./points";
import products from "./products";
import purchases from "./purchases";
import reviews from "./reviews";
import schedules from "./schedules";

export default {
    bookmarks,
    carts,
    coupons,
    fairs,
    inquires,
    introductions,
    likes,
    matchings,
    orders,
    points,
    products,
    purchases,
    reviews,
    schedules,

    get(){
        return axios.get(url).then(result => result.data);
    },
    put(data){
        return axios.put(url, data).then(result => result.data);
    },
}
