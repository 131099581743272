import axios from "@/plugins/axios";

import auth from "./auth";
import console from "./console";
import editor from "./editor";
// export { kakao } from "./kakao";
// export { naver } from "./naver";
import v1 from "./v1";

export default {
    auth,
    console,
    editor,
    v1,
    getResource(filename){
        return axios({
            url: filename,
            method: 'GET',
            responseType: 'blob'
        }).then(result => { 
            try{
                return new File([result.data], filename);
            }
            catch(error){
                var blob = new Blob([result.data]);
                blob.name = filename;
                return blob;
            }
        });
    },
};
