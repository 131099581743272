var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', _vm._l(_vm.popups, function (popup) {
    return _c(_vm.skinComponent, {
      key: popup._id,
      tag: "component",
      attrs: {
        "popup": popup
      }
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }